import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { of, switchMap } from 'rxjs';

export const ResetAuthGuard: CanActivateFn | CanActivateChildFn = (
    route,
    state
) => {
    const router: Router = inject(Router);

    // Check the authentication status
    return inject(AuthService)
        .clearTokens()
        .pipe(
            switchMap((authenticated) => {
                // we are just clearing the tokens, so return TRUE

                // Allow the access
                return of(true);
            })
        );
};
