import { BooleanInput } from '@angular/cdk/coercion';
import { CommonModule, NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { getAuth, signInWithCustomToken } from '@firebase/auth';
import { AuthService } from 'app/core/auth/auth.service';
import { UserService } from 'app/services/user.service';
import { User } from 'app/core/user/user.types';1
import { UserClient } from 'app/models/client/user-clients';
import { AccountService } from 'app/services/account.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Roles } from 'app/helpers/app-constants';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        NgClass,
        MatDividerModule,
        MatListModule,
        MatSnackBarModule,
    ],
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    allowClientSwitch: boolean;
    user: User;
    userClient$: Observable<UserClient> = this._accountService.userClient$;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _authService: AuthService,
        private _accountService: AccountService,
        private _snackBar: MatSnackBar,
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to user changes
        this._authService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;

                this.allowClientSwitch = this.user.roleCode === Roles.Client || this.user.roleCode === Roles.ClientAdministrator;

                if (this.allowClientSwitch) {
                    this._accountService.getActiveClients()
                        .subscribe((userClient) => {
                            if(userClient.userClients.length === 1) {
                                this.allowClientSwitch = false;
                            }
                        });
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        // Update the user
        this._authService
            .updateMyDetails({
                ...this.user,
                status,
            })
            .subscribe();
    }

    /**
     * Switches a client
     * @param userId userId of client to switch 
     */
    switchTo(userId: number): void {
        this._accountService.switchClient(userId).subscribe((res) => {
            this.switchWithCustomToken(res.accessToken).then(() => {
                this._accountService.getActiveClients().subscribe();
                this._navigationService.get().subscribe();
                this._router.navigate(['signed-in-redirect']);
            });
        });
    }

    /**
     * Performs authentication from a custom token
     * @param accessToken authentication access token
     * @returns 
     */
    switchWithCustomToken(accessToken: string) {
        const auth = getAuth();
    
        return signInWithCustomToken(auth, accessToken)
          .then((credential: any) => {
            this._authService.updateTokens(credential.user);
            this._authService.doRefreshTokens();
          })
          .catch(() => {
            this._snackBar.open('Failed to switch client!',
                '',
                {
                    verticalPosition: 'top',
                    duration: 3000,
                }
            );
          });
    }

    /**
     * Sign out
     */
    signOut(): void {
        this._router.navigate(['/sign-out']);
    }

    /**
     * Settings
     */
    accountSettings(): void {
        this._router.navigate(['/accounts/settings']);
    }

    /**
     * Profile
     */
    userProfile(): void {
        this._router.navigate(['/accounts/profile']);
    }

    /**
     * Change Password
     */
    changePassword(): void {
        this._router.navigate(['/accounts/change-password']);
    }
}
